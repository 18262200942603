<script lang="ts">import { getTransformValue } from "@xbs/lib-todo";
export let value = "";
export let filter;
export let completed = false;
</script>

<div
	class="wx-todo_text"
	class:wx-todo_text--completed={completed}
	tabindex="0">
	{@html getTransformValue(value, filter)}
</div>

<style>
	.wx-todo_text {
		width: 100%;
		height: 100%;
		outline: none;

		word-wrap: normal;
		word-break: break-word;
		white-space: pre-wrap;
	}
	.wx-todo_text--completed {
		color: var(--wx-color-font-disabled);
		-webkit-text-decoration: line-through var(--wx-color-font-disabled);
		        text-decoration: line-through var(--wx-color-font-disabled);
	}
	:global(.wx-todo_date),
	.wx-todo_text--completed :global(.wx-todo_date--current),
	.wx-todo_text--completed :global(.wx-todo_date--expired) {
		cursor: pointer;
		color: var(--wx-todo-date-color-regular);
	}</style>
