<script lang="ts">import Icon from "./Icon.svelte";
import { uid } from "@xbs/lib-todo";
export let disabled = false;
export let checked = false;
export let id = uid().toString();
</script>

<label
	for={id}
	class="wx-todo_checkbox wx-todo_checkbox__label"
	class:wx-todo_checkbox--disabled={disabled}>
	<input
		on:change|stopPropagation
		{id}
		{disabled}
		type="checkbox"
		class="wx-todo_checkbox__input"
		bind:checked />
	<span
		class="wx-todo_checkbox__icon"
		class:wx-todo_checkbox--checked={checked}>
		{#if checked}
			<Icon name="check" size={16} />
		{/if}
	</span>
</label>

<style>
	.wx-todo_checkbox__label {
		display: flex;
		align-items: center;
		justify-content: center;

		cursor: pointer;
		position: relative;

		width: var(--wx-checkbox-size);
		height: var(--wx-checkbox-size);
	}
	.wx-todo_checkbox__icon {
		display: flex;
		align-items: center;
		justify-content: center;

		width: var(--wx-checkbox-size);
		height: var(--wx-checkbox-size);

		border-radius: 50%;
		border: var(--wx-checkbox-border-size) solid
			var(--wx-checkbox-color-unchecked);
	}
	.wx-todo_checkbox__input {
		-webkit-appearance: none;
		   -moz-appearance: none;
		        appearance: none;
		position: absolute;
		width: 0;
		height: 0;
		left: 0;
		top: 0;
		margin: 0;
		padding: 0;
		opacity: 0;
	}
	.wx-todo_checkbox--checked {
		border: var(--wx-checkbox-border-size) solid
			var(--wx-checkbox-color-checked);
		background: var(--wx-checkbox-color-checked);
	}
	.wx-todo_checkbox--checked :global(.wxi) {
		color: var(--wx-background);
	}
	.wx-todo_checkbox--disabled {
		cursor: default;
		opacity: 0.7;
	}</style>
