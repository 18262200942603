<script>
	import { getContext } from "svelte";
	import { uid, dateToString } from "@xbs/lib-core-dom";

	import Text from "./Text.svelte";
	import Dropdown from "./Dropdown.svelte";
	import Button from "./calendar/Button.svelte";
	import TheButton from "./Button.svelte";

	import Calendar from "./Calendar.svelte";

	export let value = { start: null, end: null };
	export let id = uid();
	export let disabled = false;
	export let error = false;
	export let placeholder = "";
	export let done = false;
	export let format = "%m/%d/%Y";

	const _ = getContext("wx-i18n").getRaw().calendar;
	let dateFormat =
		typeof format === "function" ? format : dateToString(format, _);
	let popup;

	function addMonth(l, diff, rPrev) {
		const r = new Date(l);
		r.setMonth(r.getMonth() + diff);
		if (rPrev && r.valueOf() == rPrev.valueOf()) return rPrev;
		return r;
	}

	let leftCurrent, rightCurrent;
	$: {
		if (!rightCurrent)
			leftCurrent =
				value && value.start ? new Date(value.start) : new Date();
		else leftCurrent = addMonth(rightCurrent, -1, leftCurrent);
		rightCurrent = addMonth(leftCurrent, 1, rightCurrent);
	}

	function cancel(e) {
		e.stopPropagation();

		if (value && value.start) {
			leftCurrent = new Date(value.start);
		}

		popup = null;
	}

	function selectDate(e, date) {
		if (date) {
			leftCurrent = new Date(date);

			if (!value) value = { start: null, end: null };
			if (value.end || !value.start)
				value = {
					start: new Date(leftCurrent),
					end: null,
				};
			else value.end = new Date(leftCurrent);
		} else {
			leftCurrent = new Date();
			value = null;
		}
	}

	let formattedValue;
	$: formattedValue = value
		? value.start
			? dateFormat(value.start) +
			  (value.end ? ` - ${dateFormat(value.end)}` : "")
			: dateFormat(value)
		: value;

</script>

<svelte:window on:scroll={cancel} />

<div
	class="datepicker {error ? 'error' : ''} {disabled ? 'disabled' : ''}"
	on:click={() => (popup = true)}>
	<Text
		value={formattedValue}
		{id}
		readonly={true}
		{disabled}
		{placeholder}
		{error}
		inputStyle="cursor: pointer; width: 100%; padding-right: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2);" />

	<i class="icon wxi-calendar" />

	{#if popup && !disabled}
		<Dropdown {cancel} width={'unset'}>
			<div class="calendars">
				<div class="half">
					<Calendar
						bind:value
						bind:current={leftCurrent}
						part={'left'} />
				</div>
				<div class="half">
					<Calendar
						bind:value
						bind:current={rightCurrent}
						part={'right'} />
				</div>
			</div>
			<div class="buttons {done ? 'done' : ''}">
				{#if done}
					<div class="button-done">
						<TheButton type={'primary'} click={cancel}>
							{_.done}
						</TheButton>
					</div>
				{/if}
				<div class="button-item">
					<Button click={e => selectDate(e)}>{_.clear}</Button>
				</div>
				<div class="button-item">
					<Button click={e => selectDate(e, new Date())}>
						{_.today}
					</Button>
				</div>
			</div>
		</Dropdown>
	{/if}
</div>

<style>
	.datepicker {
		position: relative;
		width: var(--wx-input-width);
	}
	.datepicker.disabled .icon {
		color: var(--wx-color-font-disabled);
	}
	.datepicker.error .icon {
		color: var(--wx-color-danger);
	}

	.icon {
		position: absolute;
		right: var(--wx-input-icon-indent);
		top: 50%;
		transform: translateY(-50%);
		font-size: var(--wx-input-icon-size);
		line-height: 1;
		width: var(--wx-input-icon-size);
		height: var(--wx-input-icon-size);
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		-webkit-user-select: none;
		   -moz-user-select: none;
		    -ms-user-select: none;
		        user-select: none;
		color: var(--wx-input-icon-color);
	}
	.icon:before {
		display: block;
	}

	.calendars {
		display: flex;
	}

	.half {
		flex: 1;
	}

	.buttons {
		position: relative;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-end;
		padding: var(--wx-calendar-padding);
		padding-top: 0;
		margin-top: var(--wx-calendar-gutter);
	}
	.buttons.done .button-item {
		height: var(--wx-button-height);
		display: flex;
		align-items: center;
	}

	.button-item + .button-item {
		margin-left: var(--wx-calendar-gutter);
	}

	.button-done {
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
	}</style>
