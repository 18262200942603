<script lang="ts">import Icon from "../../library/Icon.svelte";
import Item from "../../library/Item.svelte";
import Menu from "../../library/Menu.svelte";
import { changeLabelLocale, createProject, getCountLabel, isDefined, uid, } from "@xbs/lib-todo";
import { locate, getMouseCoords } from "@xbs/lib-dom";
import { getContext } from "svelte";
export let api;
$: state = api.getReactiveState();
$: componentId = state.id;
$: menu = state.menu;
$: projects = state.projects;
$: activeProject = state.activeProject;
$: readonly = state.readonly;
const _ = getContext("wx-i18n").getGroup("todo");
$: menuData = changeLabelLocale(($menu === null || $menu === void 0 ? void 0 : $menu.data) || [], _);
function handleItemClick(event) {
    const action = event.detail.id;
    switch (action) {
        case "add":
            createProject(api === null || api === void 0 ? void 0 : api.getStores().state, {
                id: uid().toString(),
                label: getCountLabel("New project", $projects, _),
            });
            break;
        case "rename":
            api.exec("open-inline-editor", {
                id: $activeProject,
                type: "project",
            });
            break;
        case "delete":
            api.exec("delete-project", {
                id: $activeProject,
            });
            break;
    }
    action &&
        api.exec("close-menu", Object.assign({}, $menu));
}
function closeMenu(event) {
    const iconNode = locate(event.detail.event, "data-toolbar-icon");
    if (($menu === null || $menu === void 0 ? void 0 : $menu.open) && !isDefined(iconNode)) {
        api.exec("close-menu", Object.assign({}, $menu));
    }
}
function toggleMenu(event) {
    const { menu, activeProject } = api.getState();
    if ((menu === null || menu === void 0 ? void 0 : menu.open) && menu.type === "project") {
        api.exec("close-menu", Object.assign({}, menu));
        return;
    }
    api.exec("open-menu", {
        id: activeProject,
        type: "project",
        coords: getMouseCoords(event),
    });
}
</script>

{#if !$readonly}
	<button
		class="wx-todo_toolbar-menu-button"
		tabindex="0"
		data-toolbar-icon={true}
		data-todo-id={$componentId}>
		<Icon name="dots-v" on:click={toggleMenu} />
	</button>
{/if}

{#if $menu?.open && $menu?.type === 'project'}
	<Menu
		coords={$menu.coords}
		data={menuData}
		offset={10}
		let:item
		on:click={handleItemClick}
		on:cancel={closeMenu}>
		<Item
			label={item.label}
			icon={item.icon}
			disabled={item?.disabled}
			clickable />
	</Menu>
{/if}

<style>
	.wx-todo_toolbar-menu-button {
		cursor: pointer;
		height: var(--wx-base-size);
		width: var(--wx-base-size);

		display: flex;
		align-items: center;
		justify-content: center;

		outline: none;
		border: none;
		border-radius: 50%;

		background: inherit;
	}
	.wx-todo_toolbar-menu-button:hover {
		background: var(--wx-icon-background-hover);
	}</style>
