<script lang="ts">import { Locale } from "@xbs/svelte-wx";
import { en as cEN } from "@xbs/wx-core-locales";
import en from "../locales/en";
import { EventBusRouter } from "@xbs/lib-state";
import { DataStore, selection, taskMenu, userMenu, uid, keyNavigation, defaultTaskShape, addTask, tagFilter, dueDate, listEditor, getApi, isDefined, } from "@xbs/lib-todo";
import Material from "../themes/Material.svelte";
import Tree from "./Tree/Tree.svelte";
import AddButton from "./AddButton.svelte";
import { writable } from "svelte/store";
import { createEventDispatcher } from "svelte";
export let id = uid();
export let tasks = [];
export let projects = [];
export let users = [];
export let tags = [];
export let selected = null;
export let activeProject = undefined;
export let readonly = false;
export let taskShape = defaultTaskShape;
const dispatch = createEventDispatcher();
const dataStore = new DataStore(writable);
$: dataStore.init({
    tasks,
    users,
    tags,
    selected,
    taskShape: Object.assign(Object.assign({}, defaultTaskShape), taskShape),
    projects,
    activeProject,
    readonly,
    id,
});
const dr = dataStore.getReactive();
// define event route
let lastInRoute = new EventBusRouter(dispatch);
// public API
export const api = getApi(dataStore, lastInRoute);
dataStore.out.setNext(lastInRoute.exec);
function triggerAction(event) {
    const { action, data } = event.detail;
    dataStore.in.exec(action, data);
}
function handleAddTask(event) {
    if (isDefined(dataStore.getState().filter))
        return;
    event.stopPropagation();
    addTask(dataStore, {}, true);
}
</script>

<svelte:window
	use:keyNavigation={{ api, widgetId: id }}
	use:listEditor={{ api, readonly }} />

<Locale words={{ ...en, ...cEN }} optional={true}>
	<Material>
		<div class="wx-todo" data-todo-id={id}>
			{#if !readonly}
				<AddButton on:click={handleAddTask} />
			{/if}
			<div
				class="wx-todo__wrapper"
				on:contextmenu={event => event.preventDefault()}
				use:dueDate={{ api }}
				use:tagFilter={{ api }}
				use:selection={{ api }}
				use:taskMenu={{ api, readonly }}
				use:userMenu={{ api }}>
				<Tree on:action={triggerAction} dataStore={dr} />
			</div>
		</div>
	</Material>
</Locale>

<style>
	.wx-todo {
		display: flex;
		flex-direction: column;

		background: var(--wx-background);
		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		line-height: var(--wx-line-height);
		color: var(--wx-color-font);

		margin: 0;
		padding: 0;
		height: 100%;
		width: 100%;
		max-width: 100%;
		max-height: 100%;

		overflow: hidden;
	}
	.wx-todo__wrapper {
		display: flex;
		overflow-y: auto;
		height: 100%;
		width: 100%;
	}</style>
