<script lang="ts">import { Locale } from "@xbs/svelte-wx";
import en from "../locales/en";
import { en as cEN } from "@xbs/wx-core-locales";
export let api;
</script>

<Locale words={{ ...en, ...cEN }} optional={true}>
	<div class="wx-todo_toolbar">
		{#if api}
			<slot />
		{/if}
	</div>
</Locale>

<style>
	.wx-todo_toolbar {
		display: flex;
		flex-direction: row;
		align-items: var(--wx-todo-toolbar-align);
		justify-content: var(--wx-todo-toolbar-justify);

		width: 100%;
		height: var(--wx-todo-toolbar-height);
		min-height: var(--wx-todo-toolbar-height);
		max-height: var(--wx-todo-toolbar-height);
		padding: 16px;

		background: var(--wx-todo-toolbar-background);
		color: var(--wx-font-color);

		border-bottom: var(--wx-border);
	}</style>
