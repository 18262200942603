<script lang="ts">var _a;
import { isSameId } from "@xbs/lib-todo";
import { createEventDispatcher, onMount, getContext } from "svelte";
import Checkbox from "../../library/Checkbox.svelte";
import Icon from "../../library/Icon.svelte";
import Button from "../../library/Button.svelte";
import Avatar from "../../library/Avatar.svelte";
import UserMenu from "./components/UserMenu.svelte";
import TaskMenu from "./components/TaskMenu.svelte";
import Text from "./components/Text.svelte";
import Editor from "./components/Editor.svelte";
import Date from "./components/Date.svelte";
const dispatch = createEventDispatcher();
const _ = getContext("wx-i18n").getGroup("todo");
export let task;
export let level = 0;
export let selected;
export let editor;
export let menu;
export let filter;
export let tags;
export let shape;
export let readonly;
export let dueDate;
let node = null;
isSameId(task.id, selected) && onMount(() => node === null || node === void 0 ? void 0 : node.focus());
$: (dueDate === null || dueDate === void 0 ? void 0 : dueDate.open) && (node === null || node === void 0 ? void 0 : node.focus());
$: availableUsers = task === null || task === void 0 ? void 0 : task.availableUsers;
$: hasChildren = (_a = task === null || task === void 0 ? void 0 : task.children) === null || _a === void 0 ? void 0 : _a.length;
$: initialPadding = hasChildren ? 16 : 40;
$: paddingLeft = initialPadding + (level > 1 ? 24 * (level - 1) : 0);
$: editorOpen = (editor === null || editor === void 0 ? void 0 : editor.type) === "task" && isSameId(editor === null || editor === void 0 ? void 0 : editor.id, task.id);
function triggerChecked() {
    dispatch("action", {
        action: task.checked ? "uncheck-task" : "check-task",
        data: { id: task.id },
    });
}
function triggerToggle() {
    dispatch("action", {
        action: task.collapsed ? "expand-task" : "collapse-task",
        data: { id: task.id },
    });
}
function handleEditor(event) {
    const { value, dropdown, targetDate } = event.detail;
    dispatch("action", {
        action: "edit-item",
        data: {
            id: task.id,
            currentValue: value,
            dropdown,
            targetDate,
        },
    });
}
function handleTaskMenuClick(event) {
    var _a, _b;
    dispatch("action", {
        action: "click-menu-item",
        data: {
            id: menu.id,
            action: (_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.id,
            extra: (_b = event === null || event === void 0 ? void 0 : event.detail) === null || _b === void 0 ? void 0 : _b.extra,
        },
    });
}
</script>

<div
	tabindex="0"
	data-list-id={task.id}
	class="wx-todo_list"
	class:wx-todo_list--selected={isSameId(task.id, selected) && !editorOpen}
	style="padding-left:{paddingLeft}px;"
	bind:this={node}>
	<div class="wx-todo_list__controls wx-todo_list__controls-manual">
		{#if hasChildren}
			<div class="wx-todo_list-toggle-button">
				<Button circle={true} on:click={triggerToggle}>
					<Icon name={task.collapsed ? 'menu-right' : 'menu-down'} />
				</Button>
			</div>
		{/if}
		<Checkbox checked={task.checked} on:change={triggerChecked} />
	</div>
	<div class="wx-todo_list__content" data-list-editor-id={task.id}>
		<div class="wx-todo_list__text">
			{#if editorOpen && !readonly}
				<Editor
					value={task.text || ''}
					placeholder={_('Type what you want')}
					{shape}
					{editor}
					{tags}
					on:editing={handleEditor} />
			{:else}
				<Text
					value={task.text || ''}
					completed={task.checked}
					{filter} />
			{/if}
		</div>
		<Date
			id={task.id}
			datePicker={dueDate}
			format={shape?.date?.format}
			validate={shape?.date?.validate}
			completed={task.checked}
			date={task.due_date}
			on:action />
	</div>
	<div class="wx-todo_list__controls">
		{#if hasChildren}
			<div class="wx-todo_list__counter">
				{#if task.counter.type === 'number'}
					{task.counter.done}/{task.counter.total}
				{:else if task.counter.type === 'percentage'}
					{Math.round((task.counter.done / task.counter.total) * 100)}%
				{/if}
			</div>
		{/if}
		{#if availableUsers?.length}
			<div
				class="wx-todo_user-menu"
				style="width:{availableUsers.length >= 3 ? 60 : 22 * availableUsers.length}px;"
				data-user-menu-id={task.id}>
				{#each availableUsers as user, index}
					{#if (availableUsers.length === 3 && index < 3) || index < 2}
						<div
							class="wx-todo_user-menu__avatar"
							style="left:{index * 16}px">
							<Avatar color={user.color} avatar={user.avatar} />
						</div>
					{/if}
				{/each}
				{#if availableUsers.length > 3}
					<div class="wx-todo_user-menu__avatar" style="left: 32px;">
						<Avatar value={`+${availableUsers.length - 2}`} />
					</div>
				{/if}
			</div>
		{/if}
		{#if !readonly}
			<button data-menu-id={task.id} class="wx-todo_list-menu-button">
				<Icon name="dots-v" />
			</button>
		{/if}
	</div>
</div>

{#if isSameId(menu.id, task.id) && menu.open}
	{#if menu.type === 'user'}
		<UserMenu coords={menu.coords} data={availableUsers} />
	{/if}
	{#if menu.type === 'task'}
		<TaskMenu
			coords={menu.coords}
			data={menu.data}
			on:click={handleTaskMenuClick} />
	{/if}
{/if}

<style>
	.wx-todo_list {
		position: relative;
		display: flex;
		justify-content: space-between;

		outline: none;
		padding: 0 16px;
	}
	.wx-todo_list--selected {
		cursor: pointer;
		background: var(--wx-background-selected);
	}
	.wx-todo_list__content {
		display: flex;
		align-items: flex-start;
		flex-direction: column;

		width: 100%;
		padding: 8px 6px;
	}
	.wx-todo_list__controls {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	.wx-todo_list__controls-manual {
		padding: 8px 0;
	}
	.wx-todo_list__text {
		height: 100%;
		width: 100%;

		min-height: var(--wx-line-height);

		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		line-height: var(--wx-line-height);

		color: var(--wx-color-font);
	}
	.wx-todo_list__counter {
		cursor: default;
		color: var(--wx-color-secondary-light);

		margin: 8px 0 8px 6px;
		font-size: var(--wx-font-size-s);
		font-weight: var(--wx-font-weight-medium);
	}
	.wx-todo_list-menu-button {
		cursor: pointer;
		background: inherit;

		height: var(--wx-base-size);
		width: var(--wx-base-size);

		display: flex;
		align-items: center;
		justify-content: center;

		outline: none;
		border: none;
		border-radius: 50%;

		margin: 8px 0 8px 6px;
	}
	.wx-todo_list-menu-button:hover {
		background: var(--wx-icon-background-hover);
	}
	.wx-todo_list-toggle-button {
		display: flex;
		margin-right: 4px;
	}
	.wx-todo_user-menu {
		position: relative;
		height: 24px;

		display: flex;
		cursor: pointer;

		margin: 4px 0;
		margin-left: 6px;
	}
	.wx-todo_user-menu__avatar {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
	}</style>
