<script>
	export let value = "";
	export let avatar = "";
	export let color = "#0AB169";

</script>

<div
	class="wx-todo_avatar"
	style="background-color: {color}; background-image: url({avatar});">
	{value}
</div>

<style>
	.wx-todo_avatar {
		display: flex;
		align-items: center;
		justify-content: center;

		height: calc(var(--wx-base-size) + 8px);
		width: calc(var(--wx-base-size) + 8px);
		border: calc(var(--wx-border-width) * 2) solid var(--wx-background);
		border-radius: 50%;

		color: var(--wx-color-secondary-font);
		font-size: var(--wx-font-size-s);
		line-height: var(--wx-line-height);
		font-weight: var(--wx-font-weight-medium);
		font-style: normal;

		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}</style>
