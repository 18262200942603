<script lang="ts">import { setCaretPosition } from "@xbs/lib-dom";
import { createEventDispatcher } from "svelte";
import Icon from "../library/Icon.svelte";
export let placeholder = "";
export let value = "";
let node = null;
$: if (node || value)
    setTimeout(() => setCaretPosition(node));
const dispatch = createEventDispatcher();
function handleClear() {
    value = "";
    dispatch("clear");
    node === null || node === void 0 ? void 0 : node.focus();
}
</script>

<div class="wx-todo_input">
	<input
		bind:this={node}
		bind:value
		on:keypress
		on:focus
		on:blur
		class="wx-todo_input__value"
		{placeholder} />
	{#if value}
		<Icon name="close" clickable on:click={handleClear} />
	{/if}
</div>

<style>
	.wx-todo_input {
		display: flex;
		align-items: center;

		width: 100%;
	}
	.wx-todo_input__value {
		border: none;
		outline: none;

		color: var(--wx-color-font);
		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		background: inherit;

		height: 100%;
		width: 100%;
	}</style>
