<script lang="ts">import { createEventDispatcher } from "svelte";
import { locateID } from "@xbs/lib-dom";
import { isDefined, isSameId } from "@xbs/lib-todo";
import Popup from "./Popup.svelte";
export let id = null;
export let data = [];
export let coords;
export let offset = 0;
export let width = "auto";
export let height = "auto";
export let clickable = true;
let selected = null;
const dispatch = createEventDispatcher();
function handleMenuClick(event) {
    if (!clickable)
        return;
    selected = locateID(event, "data-item-id");
    dispatch("click", {
        id: selected,
    });
}
function handleMouseDown(event) {
    var _a, _b;
    const index = data.findIndex(i => isSameId(i.id, selected));
    switch (event.code) {
        case "ArrowUp":
            event.preventDefault();
            selected = isDefined(selected)
                ? (_a = data[index - 1]) === null || _a === void 0 ? void 0 : _a.id
                : data[data.length - 1].id;
            break;
        case "ArrowDown":
            event.preventDefault();
            selected = isDefined(selected)
                ? (_b = data[index + 1]) === null || _b === void 0 ? void 0 : _b.id
                : data[0].id;
            break;
        case "Enter":
            event.preventDefault();
            dispatch("click", { id: selected });
            break;
    }
}
</script>

<svelte:window on:keydown={handleMouseDown} />

<Popup {coords} {offset} {id} on:cancel>
	<ul
		class="wx-todo_menu"
		on:click|stopPropagation={handleMenuClick}
		style="width:{typeof width === 'number' ? `${width}px` : width};
			height:{typeof height === 'number' ? `${height}px` : height};">
		{#each data as item (item.id)}
			<li
				class="wx-todo_menu__item"
				class:wx-todo_menu__item--selected={isSameId(item.id, selected) && clickable}
				data-item-id={item?.disabled ? null : item?.id}>
				<slot {item} />
			</li>
		{/each}
	</ul>
</Popup>

<style>
	.wx-todo_menu {
		overflow: auto;
	}
	.wx-todo_menu,
	.wx-todo_menu__item {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	.wx-todo_menu__item--selected {
		background: var(--wx-background-selected);
	}</style>
