<script>
	import { getContext } from "svelte";

	import Header from "./calendar/Header.svelte";
	import Month from "./calendar/Month.svelte";
	import Year from "./calendar/Year.svelte";
	import Duodecade from "./calendar/Duodecade.svelte";
	import Button from "./calendar/Button.svelte";

	const _ = getContext("wx-i18n").getGroup("calendar");

	export let value;
	export let current;
	export let cancel = null;
	export let part = "normal";
	export let markers = null;
	export let buttons = true;

	$: current = current || value || new Date();

	let type = "month";
	const types = {
		month: {
			component: Month,
			next: nextMonth,
			prev: prevMonth,
			cancel: cancelMonth,
		},
		year: {
			component: Year,
			next: nextYear,
			prev: prevYear,
			cancel: backToMonth,
		},
		duodecade: {
			component: Duodecade,
			next: nextDuodecade,
			prev: prevDuodecade,
			cancel: backToYear,
		},
	};

	function prevMonth() {
		let newCurrent = new Date(current);
		newCurrent.setMonth(current.getMonth() - 1);
		while (current.getMonth() === newCurrent.getMonth()) {
			newCurrent.setDate(newCurrent.getDate() - 1);
		}
		current = newCurrent;
	}
	function nextMonth() {
		current.setMonth(current.getMonth() + 1);
		current = current;
	}
	function prevYear() {
		current.setFullYear(current.getFullYear() - 1);
		current = current;
	}
	function nextYear() {
		current.setFullYear(current.getFullYear() + 1);
		current = current;
	}
	function prevDuodecade() {
		current.setFullYear(current.getFullYear() - 10);
		current = current;
	}
	function nextDuodecade() {
		current.setFullYear(current.getFullYear() + 10);
		current = current;
	}

	function cancelMonth() {
		if (value) {
			if (part === "normal") current = new Date(value);
		}
		type = "month";
		if (cancel) cancel();
	}
	function backToMonth() {
		type = "month";
	}
	function backToYear() {
		type = "year";
	}

	function selectDate(e, date) {
		e.stopPropagation();
		if (date) {
			current = new Date(date);
			value = new Date(current);
		} else {
			value = null;
			current = new Date();
		}
		if (part === "normal") cancelMonth();
	}

</script>

<div class="calendar {part !== 'normal' ? 'part' : ''}">
	<div class="wrap">
		<Header
			date={current}
			{part}
			bind:type
			prev={types[type].prev}
			next={types[type].next} />
		<div class="body">
			<svelte:component
				this={types[type].component}
				bind:value
				bind:current
				{part}
				{markers}
				cancel={types[type].cancel} />
			{#if type === 'month' && part === 'normal' && buttons}
				<div class="buttons">
					<div class="button-item">
						<Button click={e => selectDate(e)}>{_('clear')}</Button>
					</div>
					<div class="button-item">
						<Button click={e => selectDate(e, new Date())}>
							{_('today')}
						</Button>
					</div>
				</div>
			{/if}
		</div>
	</div>
</div>

<style>
	.calendar {
		height: auto;
		width: 100%;
		padding: var(--wx-calendar-padding);
		cursor: default;
		font-family: var(--wx-calendar-font-family);
		font-size: var(--wx-calendar-font-size);
		line-height: var(--wx-calendar-line-height);
		font-weight: var(--wx-calendar-font-weight);
		color: var(--wx-calendar-font-color);
	}
	.calendar.part {
		padding-bottom: 0;
	}

	.wrap {
		width: calc(
			var(--wx-calendar-cell-size) * 7 + var(--wx-calendar-cell-gap) * 6
		);
		margin: 0 auto;
	}

	.buttons {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-end;
		margin-top: var(--wx-calendar-gutter);
	}

	.button-item + .button-item {
		margin-left: var(--wx-calendar-gutter);
	}</style>
