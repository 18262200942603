<script>
	import { getContext } from "svelte";
	import Button from "../library/Button.svelte";
	import Icon from "../library/Icon.svelte";
	import Separator from "../library/Separator.svelte";

	const _ = getContext("wx-i18n").getGroup("todo");

</script>

<div class="wx-todo_button-add">
	<Button on:click height={36} width={'100%'}>
		<div class="wx-todo_button-add__content">
			<div class="wx-todo_button-add__icon">
				<Icon name={'plus'} size={16} />
			</div>
			<span class="wx-todo_button-add__text">{_('Add task')}</span>
		</div>
	</Button>
	<Separator />
	{#if false}
		<mark
			class="wx-todo_mark"
			class:wx-todo_mark--error={0 < new Date()}>
			{window.atob('VHJpYWw=')}
		</mark>
	{/if}
</div>

<style>
	.wx-todo_button-add {
		margin-top: 6px;
		width: 100%;
	}
	.wx-todo_button-add__content {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		height: 100%;

		padding: 0 20px 0 40px;

		color: var(--wx-color-primary);
		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		line-height: var(--wx-line-height);
	}
	.wx-todo_button-add__icon :global(.wxi) {
		color: var(--wx-color-primary);
	}
	.wx-todo_button-add__text {
		margin-left: 8px;
	}

	.wx-todo_mark {
		position: absolute;
		right: 34px;
		top: 12px;
		transform: rotate(30deg);
		color: #ccc;
		font-weight: 500;
		text-transform: uppercase;
		background: inherit;
	}
	.wx-todo_mark.wx-todo_mark--error {
		color: red;
	}</style>
