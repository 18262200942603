<script lang="ts">export let width = 20;
export let height = 20;
export let circle = false;
</script>

<button
	on:click
	class="wx-todo_button"
	class:wx-todo_button--circle={circle}
	style="width:{typeof width === 'number' ? `${width}px` : width};
		height:{typeof height === 'number' ? `${height}px` : height}">
	<slot />
</button>

<style>
	.wx-todo_button {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;

		background: inherit;
		outline: none;
		border: none;
		padding: 0;
	}
	.wx-todo_button--circle {
		border-radius: 50%;
	}</style>
