<script>
	import { Material } from "@xbs/svelte-wx";
	const SLOTS = $$props.$$slots;

</script>

{#if SLOTS && SLOTS.default}
	<Material>
		<div class="wx-material-theme" style="height:100%;width:100%;">
			<slot />
		</div>
	</Material>
{/if}

<style global>
	:global(.wx-material-theme) :global(*) {
		box-sizing: border-box;
	}
	:global(.wx-material-theme) {
		/* base colors */
		--wx-color-primary: #0a76bf;
		--wx-color-success: #0ab169;
		--wx-color-danger: #ff5252;

		--wx-color-font: #4d4d4d;
		--wx-color-font-disabled: #b3b3b3;

		--wx-color-secondary: #808080;
		--wx-color-secondary-hover: #4d4d4d;
		--wx-color-secondary-font: #fff;
		--wx-color-secondary-light: #b3b3b3;

		--wx-background: #fff;
		--wx-background-selected: #ededed;
		/* end base colors */

		/* font */
		--wx-font-family: Roboto, Arial, Helvetica, sans-serif;
		--wx-font-size: 14px;
		--wx-font-size-s: 12px;
		--wx-font-weight: 400;
		--wx-font-weight-medium: 500;
		--wx-line-height: 20px;
		/* end font */

		/* icons */
		--wx-icon-color: var(--wx-color-secondary);
		--wx-icon-background-hover: #d4d4d4;
		/* end icons */

		/* animation */
		--wx-all-transition: all 0.2s ease-out;
		/* end animation */

		/* border */
		--wx-border-color: #dfdfdf;
		--wx-border-width: 1px;
		--wx-border-radius: 2px;
		--wx-border: var(--wx-border-width) solid var(--wx-border-color);
		/* end border */

		/* checkbox */
		--wx-checkbox-size: var(--wx-base-size);
		--wx-checkbox-border-size: 2px;
		--wx-checkbox-color-unchecked: var(--wx-color-secondary);
		--wx-checkbox-color-checked: var(--wx-color-success);
		/* end checkbox */

		/* Main component */
		--wx-todo-toolbar-background: var(--wx-background);
		--wx-todo-toolbar-height: 56px;
		--wx-todo-toolbar-align: center;
		--wx-todo-toolbar-justify: flex-start;

		--wx-todo-mark-background: #ffff88;
		--wx-todo-mark-background-strict: #d4c5f9;

		--wx-todo-date-color-current: var(--wx-color-success);
		--wx-todo-date-color-expired: var(--wx-color-danger);
		--wx-todo-date-color-regular: var(--wx-color-secondary);
		/* End main component */

		/* other */
		--wx-base-size: 20px;
		--wx-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
		/* end other */
	}
	:global(.wx-todo_filter) {
		background: var(--wx-todo-mark-background);
	}
	:global(.wx-todo_filter--strict) {
		background: var(--wx-todo-mark-background-strict);
	}
	:global(.wx-todo_tag) {
		cursor: pointer;
		color: var(--wx-color-primary);
	}
	:global(.wx-todo_date--current) {
		color: var(--wx-todo-date-color-current);
	}
	:global(.wx-todo_date--expired) {
		color: var(--wx-todo-date-color-expired);
	}</style>
