<script>
	export let padding = "6px 16px";

</script>

<div class="wx-todo_separator" style="padding: {padding};">
	<div class="wx-todo_separator__line" />
</div>

<style>
	.wx-todo_separator {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.wx-todo_separator__line {
		border-bottom: var(--wx-border);
		flex: 1 1 auto;
	}</style>
