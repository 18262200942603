<script lang="ts">var _a, _b, _c;
import { getTagsData, isDefined } from "@xbs/lib-todo";
import { getContext } from "svelte";
import Icon from "../../library/Icon.svelte";
import Input from "../../library/Input.svelte";
import Menu from "../../library/Menu.svelte";
import Item from "../../library/Item.svelte";
export let api;
$: state = api.getReactiveState();
$: componentId = state.id;
$: filter = state.filter;
$: tags = state.tags;
$: search = state.stateSearch;
let node = null;
let handleValue = null;
const _ = getContext("wx-i18n").getGroup("todo");
$: if (isDefined($search === null || $search === void 0 ? void 0 : $search.value) &&
    $search.value !== (($filter === null || $filter === void 0 ? void 0 : $filter.match) || "") &&
    $search.focus) {
    if (!((_a = $search.value) === null || _a === void 0 ? void 0 : _a.length)) {
        api.exec("set-filter", { match: null });
    }
    else {
        api.exec("set-filter", {
            match: $search.value,
            highlight: true,
            strict: ((_b = $search.value) === null || _b === void 0 ? void 0 : _b.startsWith("#")) &&
                $tags.includes($search.value),
        });
    }
    if (node &&
        ((_c = $search.value) === null || _c === void 0 ? void 0 : _c.startsWith("#")) &&
        handleValue !== ($search === null || $search === void 0 ? void 0 : $search.value)) {
        openDropdown();
    }
    else {
        closeDropdown();
    }
}
function searchToggle() {
    api.exec("set-state-search", Object.assign(Object.assign({}, $search), { open: !($search === null || $search === void 0 ? void 0 : $search.open) }));
}
function handleFocus() {
    api.exec("set-state-search", Object.assign(Object.assign({}, $search), { focus: true }));
}
function handleBlur() {
    var _a;
    api.exec("set-state-search", Object.assign(Object.assign({}, $search), { focus: false, open: !!((_a = $search.value) === null || _a === void 0 ? void 0 : _a.length) }));
}
function handleItemClick(event) {
    handleValue = event.detail.id;
    api.exec("set-state-search", Object.assign(Object.assign({}, $search), { value: (handleValue === null || handleValue === void 0 ? void 0 : handleValue.length) ? handleValue : null, dropdown: { open: false } }));
}
function openDropdown() {
    const data = getTagsData($tags, $search.value);
    const rect = node.getBoundingClientRect();
    setTimeout(() => {
        api.exec("set-state-search", Object.assign(Object.assign({}, $search), { open: true, dropdown: {
                open: true,
                coords: { x: rect.left, y: rect.bottom },
                data,
            } }));
    }, ($search === null || $search === void 0 ? void 0 : $search.open) ? 0 : 250);
}
function closeDropdown() {
    api === null || api === void 0 ? void 0 : api.exec("set-state-search", Object.assign(Object.assign({}, $search), { dropdown: {
            open: false,
        } }));
    handleValue = null;
}
</script>

<div
	data-todo-id={$componentId}
	class="wx-todo_search"
	class:wx-todo_search--opened={$search?.open}>
	<Icon name="search" clickable on:click={searchToggle} />
	{#if $search?.open}
		<div class="wx-todo_search__input" bind:this={node}>
			<Input
				placeholder={_('Search')}
				bind:value={$search.value}
				on:focus={handleFocus}
				on:blur={handleBlur} />
		</div>
	{/if}
</div>

{#if node && $search?.dropdown?.open}
	<Menu
		offset={10}
		coords={$search.dropdown.coords}
		data={$search.dropdown.data}
		on:click={handleItemClick}
		on:cancel={closeDropdown}
		let:item>
		<Item label={item.label} clickable />
	</Menu>
{/if}

<style>
	.wx-todo_search {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		height: 100%;
		margin-right: 12px;
		width: 20px;

		transition: var(--wx-all-transition);
	}
	.wx-todo_search--opened {
		width: 100%;
		transition: var(--wx-all-transition);
	}
	.wx-todo_search__input {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		width: 100%;
		height: 100%;
		padding: 0 6px;
	}</style>
